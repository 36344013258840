import { Tabs } from '@mantine/core';
import { PanelProps } from './type';
import { RFC } from '@app/ui/type';
import React from 'react';


export const NotebookPanel : RFC<PanelProps> = ({
    children, value,
}) => {

    return (
        <Tabs.Panel value={value}>
            {children}
        </Tabs.Panel>
    );
};

import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { DefaultObjective } from '@app/constant/default/DefaultObjective';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { Objective } from '@app/ui/model/Objective';
import { useDispatch } from '@app/client/hook';
import { Spacer } from '@app/ui/layout/Spacer';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import Link from 'next/link';
import React from 'react';
import { CA } from '@app/type/framework/core/CoreAction';
import { ObjectiveModel } from '@app/type/model/ObjectiveModel';
import { Action } from '@app/client/action';


export const PageBrowseObjective : RFC<Props> = ({
    value : objectiveList, composeModel,
}) => {

    const dispatch = useDispatch();

    console.log('humm', classes.root);

    if (!objectiveList)
        return <LoadingSpinner label='Loading Objectives' />;

    const handleChangeCompose = (newObjective : ObjectiveModel) : CA =>
        dispatch(Action.updateChatObjective(newObjective));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Objective));

    return (
        <BrowseModelLayout
            dialogModalStateType={DialogModalStateType.Closed}
            composingModel={DefaultObjective}
            newButtonContent='Create an Objective'
            modelType={ModelType.Objective}
            onNewModal={handleNewModal}
            title='Meeting Objectives'
            modelList={objectiveList}
            renderItem={item =>
                <Link
                    href={`/objective/${item.hash}`}
                    className={classes.link}>
                    <Objective
                        value={item as ObjectiveModel}
                        selectedChatHash={null}
                        mode={ControlModeType.Row}
                        defaultTab={null} />
                    <br />
                    <Spacer showLine={true} />
                </Link>
            }
            renderCompose={
                <Objective
                    onChange={handleChangeCompose}
                    selectedChatHash={null}
                    mode={ControlModeType.Create}
                    value={composeModel}
                    defaultTab={null} />
            } />
    );
};

import { PanelPlaceholder } from '@app/ui/layout/PanelPlaceholder';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { ChatContext } from './ChatContext';
import { ChatCreate } from './ChatCreate';
import { ChatView } from './ChatView';
import { ChatRow } from './ChatRow';
import { RFC } from '@app/ui/type';
import { ReactNode } from 'react';
import { Props } from './type';


export const Chat : RFC<Props> = ({
    relatedChatListGrouped, belongsToModel,
    value : chat, onChange, mode,
}) => {

    if (!chat) return <LoadingSpinner label='Loading Chat' />;

    const renderChat = () : ReactNode => {
        switch (mode) {
            case ControlModeType.Create: return <ChatCreate onChange={onChange} />;
            case ControlModeType.View  : return <ChatView />;
            case ControlModeType.Row   : return <ChatRow />;

            default: return <PanelPlaceholder label={`Chat ControlModeType ${mode}`} />;
        }
    };

    return (
        <ChatContext.Provider
            value={{
                relatedChatListGrouped, belongsToModel, chat,
            }}>
            {renderChat()}
        </ChatContext.Provider>
    )
};

import { ObjectiveType } from '@app/constant/enum/model/ObjectiveTypeEnum';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';
import { ThemeIcon, Group, Text, Box } from '@mantine/core';
import QuillNoSSRWrapper from './QuillWrapper';
import { Icon } from '@app/ui/static/Icon';
import classes from './style.module.css';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';


export const ObjectiveRow : RFC<ViewProps> = ({
    objective,
}) => {
    const renderIcon = () : React.ReactNode | null => {

        switch (objective.objectiveType) {
            case ObjectiveType.Generic: return (
                <ThemeIcon
                    variant='outline'
                    radius='md'
                    color='#FFC107'
                    size='md'>
                    <Icon iconName={IconName.Check} />
                </ThemeIcon>
            );
        }
    };

    const handleTextEdit = (_text : string) : void => {};

    return (
        <Box className={classes.objective}>
            <Group align='apart'>
                <Box>
                    <Text className={classes.title}>
                        {objective.title}
                    </Text>
                </Box>
                <Box>
                    {renderIcon()}
                </Box>
            </Group>

            <br/>
            {objective.description &&
                <Text className={classes.description}>
                    <QuillNoSSRWrapper
                        className={classes.textEditor}
                        onChange={handleTextEdit}
                        value={objective.description.replaceAll('\n', '<br />')} />
                </Text>
            }
        </Box>
    );
};

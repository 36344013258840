import { ModelChat } from '@app/ui/control/ModelChat';
import { ResearchPanelProps } from '../../type';
import { TabValue } from '../../constant';
import { Tabs } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PanelResearch : RFC<ResearchPanelProps> = ({
    relationshipConfig,
    belongsToModel,
    chatList,
}) =>
    <Tabs.Panel
        value={TabValue.Research}
        pl='xl'
        pr='xl'
        pt='xl'>
        <ModelChat
            relationshipConfig={relationshipConfig}
            belongsToModel={belongsToModel}
            isResearching={false}
            chatList={chatList} />
    </Tabs.Panel>

import { Title } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const ResearchTitle : RFC = () =>
    <Title
        order={2}
        fw={300}
        pb='md'>
        {'Ask Research Assistant'}
    </Title>

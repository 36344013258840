import { ContactList } from '@app/ui/list/ContactList';
import { TabValue } from '../../constant';
import { Tabs } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PanelRelationships : RFC = () =>
    <Tabs.Panel
        value={TabValue.Relationships}
        p='xl'>
        <ContactList contactList={[]} />
    </Tabs.Panel>;

import { PanelPlaceholder } from '@app/ui/layout/PanelPlaceholder';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { AssetCreate } from './AssetCreate';
import { AssetDetail } from './AssetDetail';
import { AssetRow } from './AssetRow';
import { RFC } from '@app/ui/type';
import { Props } from './type';


// todo - Add context provider

export const Asset : RFC<Props> = ({
    showManageControls,
    pathway : _pathway,
    relationshipList,
    value : asset,
    document,
    onChange,
    mode,
}) => {

    if (!asset)
        return <LoadingSpinner label='Loading Asset' />;

    switch (mode) {
        case ControlModeType.View:
            return <AssetDetail
                showManageControls={showManageControls}
                relationshipList={relationshipList}
                document={document}
                asset={asset} />;

        case ControlModeType.Row:
            return <AssetRow
                showManageControls={showManageControls}
                relationshipList={relationshipList}
                document={document}
                asset={asset} />;

        case ControlModeType.Create:
            return <AssetCreate
                showManageControls={showManageControls}
                relationshipList={relationshipList}
                document={document}
                onChange={onChange}
                asset={asset} />;

        default:
            return <PanelPlaceholder label={`Asset ControlModeType ${mode}`} />;
    }
};

import { RFC } from '@app/ui/type';
import { ViewProps } from './type';
import { CoreItemRow } from '@app/ui/layout/CoreItemRow';


export const ContactRow : RFC<ViewProps> = ({
    contact, showManageControls : _showManageControls,
}) => {

    return (
        <CoreItemRow model={contact}>
            {/* {'Additional Fields'} */}
        </CoreItemRow>
    );
};

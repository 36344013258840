import { Tabs, Text, rem } from '@mantine/core';
import { PattentOfficeTabProps } from './type';
import { RFC } from '@app/ui/type';


export const PatentOfficeTab : RFC<PattentOfficeTabProps> = ({
    value, label,
}) => {
    return (
        <Tabs.Panel value={value}>
            <Text
                size={rem(26)}
                ta='center'
                fw={200}
                pb='sm'>
                {label}
            </Text>
        </Tabs.Panel>
    );
}

import { Box, Button, Group, Tabs, Text, rem } from '@mantine/core';
import { HorizontalSpacer } from '@app/ui/layout/HorizontalSpacer';
import { TextEditor } from '@app/ui/control/TextEditor';
import { NotebookPanel } from './NotebookPanel';
import { FcDocument } from 'react-icons/fc';
import { PiNotepad } from 'react-icons/pi';
import { useId } from '@mantine/hooks';
import { MockData } from './constant';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';


export const Notebook : RFC<Props> = ({
    model : _model, orientation,
}) => {
    const id = useId();

    return (
        <Box h='100%'>
            <Group
                ml={rem(224)}
                w='100%'
                wrap='nowrap'>
                <FcDocument size={40} />
                <Group

                    wrap='nowrap'
                    w='100%'
                    justify='space-between'>
                    <Text
                        fz={rem(46)}
                        fw={200}
                        pb='md'>
                        {'Research Findings'}
                    </Text>
                    <Button
                        size='lg'
                        aria-label='New Note'
                        m='md'
                        variant='light'>
                        {'+ New Note'}
                    </Button>
                </Group>
            </Group>
            <Tabs
                defaultValue={MockData[0].title}
                orientation={orientation}>
                <Tabs.List>
                    {MockData.map((data, index) => (
                        <Tabs.Tab
                            key={`${id}-tab-${index}`}
                            style={{
                                borderBottom : '1px solid #e0e0e0',
                            }}
                            value={data.title}>
                            <Group wrap='nowrap'>
                                <PiNotepad size={20} />
                                <Text fz={rem(20)}>
                                    {data.title}
                                </Text>
                            </Group>
                        </Tabs.Tab>
                    ))}
                    <Tabs.Tab value='new'>
                        <Button
                            aria-label='New Note'
                            m='md'
                            size='lg'
                            variant='light'>
                            {'+ New Note'}
                        </Button>
                    </Tabs.Tab>
                </Tabs.List>
                {MockData.map((data, index) => (
                    <NotebookPanel
                        key={`${id}-panel-${index}`}
                        value={data.title}>
                        <TextEditor content={data.content} />
                    </NotebookPanel>
                ))}
            </Tabs>
            <HorizontalSpacer size='xs' />
        </Box>
    );
};

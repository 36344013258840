import { selectLookupRelationshipList, selectLookupChat } from '@app/client/selector/ListSelector';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { LayoutModelDetail } from '@app/ui/template/LayoutModelDetail';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { Orientation } from '@app/constant/framework/Orientation';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { PanelRelationships } from './Tab/PanelRelationships';
import { PanelRevisions } from './Tab/PanelRevisions';
import { ChatModel } from '@app/type/model/ChatModel';
import { PanelAnalysis } from './Tab/PanelAnalysis';
import { PanelResearch } from './Tab/PanelResearch';
import { useSelector } from '@app/client/hook';
import { PanelNotes } from './Tab/PanelNotes';
import { FcDocument } from 'react-icons/fc';
import { Grid, Tabs } from '@mantine/core';
import { AssetError } from '../AssetError';
import { HeaderTabs } from './HeaderTabs';
import { HeaderBar } from './HeaderBar';
import { TabValue } from '../constant';
import { ViewProps } from '../type';
import { RFC } from '@app/ui/type';
import { useState } from 'react';


export const AssetDetail : RFC<ViewProps> = ({
    relationshipList : _relationshipList,
    document         : doc,
    asset,
}) => {

    const relationshipLookup = useSelector(selectLookupRelationshipList);
    const lookupChat         = useSelector(selectLookupChat);

    const [activeTopTab, setActiveTopTab] = useState<string | null>(TabValue.Research);

    if(!asset || !asset.hash || !asset.id)
        return <LoadingSpinner label='Loading Document...' />;

    const hasFailedConversion = () : boolean =>
        doc.textractJobStatus === 'FAILED' // todo: use constants
        || doc.textractStatusMessage === 'INVALID_IMAGE_TYPE';

    if (hasFailedConversion())
        return (
            <LayoutModelDetail
                description={doc.fileType || '[no description]'}
                title={doc.fileName || 'Untitled'}
                titleIcon={<FcDocument size={50} /> }>
                <AssetError document={doc} />
            </LayoutModelDetail>
        );

    let defaultTab = TabValue.Analysis;

    // opt for the more interesting
    // tab as new data becomes available
    if(doc.textractText || doc.analysisSummary)
        defaultTab = TabValue.Research;

    const assetRelationships = relationshipLookup[asset.hash!];

    const chatHashList = assetRelationships.filter(relationship => {
        return relationship.sourceModelType === ModelType.Chat;
    }).map(relationship => relationship.sourceModelHash);

    const chatList : ChatModel[] = [];

    chatHashList.forEach(hash => {
        chatList.push(lookupChat[hash!]);
    });

    const relationshipConfig : RelationshipConfig = {
        [RT.BelongsTo] : asset,
    };

    return (
        <LayoutModelDetail
            description={doc.fileType || '[no description]'}
            title={doc.fileName || 'Untitled'}
            titleIcon={<FcDocument size={40} /> }>
            <HeaderBar asset={asset} />
            <Grid >
                <Grid.Col
                    span={12}
                    pl='xl'
                    pr='xl'>
                    <Tabs
                        orientation={Orientation.Horizontal}
                        onChange={setActiveTopTab}
                        defaultValue={defaultTab}
                        value={activeTopTab}
                        variant='outline'>
                        <HeaderTabs />
                        <PanelAnalysis />
                        <PanelResearch
                            relationshipConfig={relationshipConfig}
                            belongsToModel={asset}
                            chatList={chatList} />
                        <PanelNotes asset={asset} />
                        <PanelRelationships />
                        <PanelRevisions />
                    </Tabs>
                </Grid.Col>
            </Grid>
        </LayoutModelDetail>
    );
};

export interface NoteBookData {
    content : string;
    title   : string;
    id      : string;
}

/* eslint-disable max-len */

export const MockData : NoteBookData[]= [{
    content : '<h2>Research Findings</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ultricies nunc. Sed et turpis vitae urna lacinia aliquet. Nulla facilisi.</p><ul><li>List item 1</li><li>List item 2</li></ul><blockquote>A quote related to the research findings</blockquote><pre><code>const research = true;</code></pre>',
    title   : 'Research Findings',
    id      : 'research-findings',
}, {
    content : '<h2>Group Work</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ultricies nunc. Sed et turpis vitae urna lacinia aliquet. Nulla facilisi.</p><ul><li>List item 1</li><li>List item 2</li></ul><blockquote>A quote related to the group work</blockquote><pre><code>const groupWork = true;</code></pre>',
    title   : 'Group Work',
    id      : 'group-work',
}, {
    content : '<h2>More Notes</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ultricies nunc. Sed et turpis vitae urna lacinia aliquet. Nulla facilisi.</p><ul><li>List item 1</li><li>List item 2</li></ul><blockquote>A quote related to the notes</blockquote><pre><code>const moreNotes = true;</code></pre>',
    title   : 'More Notes',
    id      : 'more-notes',
}];


/* eslint-enable max-len */

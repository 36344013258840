import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { PanelPlaceholder } from '@app/ui/layout/PanelPlaceholder';
import { ContactCreate } from './ContactCreate';
import { ContactView } from './ContactView';
import { ContactRow } from './ContactRow';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Contact : RFC<Props> = ({
    responsiveScreenType : _responsiveScreenType,
    value                : contact,
    showManageControls,
    onChange,
    pathway,
    mode,
}) => {

    if (!contact)
        return <PanelPlaceholder label='No Contact Info' />;

    switch (mode) {
        case ControlModeType.View:
            return <ContactView
                showManageControls={showManageControls}
                pathway={pathway}
                contact={contact} />;

        case ControlModeType.Row:
            return <ContactRow
                showManageControls={showManageControls}
                contact={contact} />;

        case ControlModeType.Create:
            return <ContactCreate
                showManageControls={showManageControls}
                onChange={onChange}
                contact={contact} />;

        default:
            return <PanelPlaceholder label={`Contact ControlModeType ${mode}`} />;
    }
};

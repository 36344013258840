import { Orientation } from '@app/constant/framework/Orientation';
import { ResearchBox } from '@app/ui/control/ResearchBox';
import { ChatModel } from '@app/type/model/ChatModel';
import { ChatList } from '@app/ui/list/ChatList';
import { Box, Tabs, rem } from '@mantine/core';
import { useContext, useState } from 'react';
import { App } from '@app/ui/AppContext';
import { TabHeader } from './TabHeader';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const ModelChat : RFC<Props> = ({
    relationshipConfig,
    belongsToModel,
    isResearching,
    chatList,
}) => {
    const { isPhone, isTablet } = useContext(App);

    const [activeTab, setActiveTab] = useState<string | null>('NEW_RESEARCH_TAB');

    const orientation = (isPhone || isTablet)
        ? Orientation.Horizontal
        : Orientation.Vertical;

    // group chats by groupHash making the key the grouphash and the array of chats the value
    const chatListGrouped = chatList
        .filter(chat => chat.relatedContentHash === null)
        .reduce((acc, chat) => {
            if (!acc[chat.groupHash!])
                acc[chat.groupHash!] = [];

            acc[chat.groupHash!].push(chat);

            return acc;
        }, {} as Record<string, ChatModel[]>);

    const relatedChatListGrouped = chatList
        .filter(chat => chat.relatedContentHash !== null)
        .reduce((acc, chat) => {
            if (!acc[chat.relatedContentHash!])
                acc[chat.relatedContentHash!] = [];

            acc[chat.relatedContentHash!].push(chat);

            return acc;
        }, {} as Record<string, ChatModel[]>);

    return (
        <Box>
            <Tabs
                defaultValue='NEW_RESEARCH_TAB'
                orientation={orientation}
                onChange={setActiveTab}
                value={activeTab}
                radius={rem(10)}>
                <TabHeader
                    activeTab={activeTab}
                    chatListGrouped={chatListGrouped} />
                <Tabs.Panel value='NEW_RESEARCH_TAB'>
                    <ResearchBox
                        relationshipConfig={relationshipConfig}
                        researchControlId='NEW_RESEARCH_TAB'
                        isResearching={isResearching} />
                </Tabs.Panel>
                {chatListGrouped && Object.keys(chatListGrouped).map(groupHash => (
                    <Tabs.Panel
                        value={groupHash}
                        key={groupHash}>
                        <ResearchBox
                            relationshipConfig={relationshipConfig}
                            researchControlId={groupHash}
                            isResearching={isResearching} />
                        <ChatList
                            relatedChatListGrouped={relatedChatListGrouped}
                            chatList={chatListGrouped[groupHash]}
                            belongsToModel={belongsToModel}
                            pathwayMilestoneList={[]}
                            showRowContent={false}
                            pathway={null} />
                    </Tabs.Panel>
                ))}
            </Tabs>
        </Box>
    )
}

import { RFC } from '@app/ui/type';
import { Tabs, Text } from '@mantine/core';


export const PatentOrgTabs : RFC = () =>
    <Tabs.List mb='md'>
        <Tabs.Tab value='USPO'>
            <Text size='xl'>
                {'USPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='EPO'>
            <Text size='xl'>
                {'EPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='JPO'>
            <Text size='xl'>
                {'JPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='KIPO'>
            <Text size='xl'>
                {'KIPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='WIPO'>
            <Text size='xl'>
                {'WIPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='IPO'>
            <Text size='xl'>
                {'IPO'}
            </Text>
        </Tabs.Tab>
        <Tabs.Tab value='SPIO'>
            <Text size='xl'>
                {'SPIO'}
            </Text>
        </Tabs.Tab>
    </Tabs.List>

import { ScrollArea, Text , Avatar, Group, Paper, List } from '@mantine/core';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import classes from './style.module.css';
import { useId } from '@mantine/hooks';
import { ReactElement } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const CommentSimple = ({ children } : {
    children : ReactElement | string;
}) : ReactElement => {
    return (

        <Group p='xs'>
            <Avatar
                // eslint-disable-next-line max-len
                src='https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80'
                alt='Jacob Warnhalter'
                radius='xl'/>
            <Text
                size='sm'>
                {children}
            </Text>
        </Group>

    );
};


export const Transcript : RFC<Props> = ({
    value : transcriptList,
}) => {    const id = useId();

    if (!transcriptList || transcriptList.length > 0 && !transcriptList[0])
        return <LoadingSpinner label={`Loading transcript (${transcriptList.length})...`} />;

    return (
        <Paper
            className={classes.transcript}
            shadow='xs'>
            <ScrollArea
                scrollbarSize={36}>
                <List>
                    {transcriptList.map((transcription, index) =>
                        <List.Item
                            key={`${id}-${index}-${transcription.title}`}
                            className={classes.listItem}>
                            <CommentSimple >
                                <>
                                    {transcription.title}
                                    <Text
                                        size='xs'
                                        c='dimmed'>
                                        {`${new Date(transcription.createdAt ?? new Date()).toLocaleTimeString()} - `}
                                        {'10 minutes ago'}
                                    </Text>
                                </>
                            </CommentSimple>
                        </List.Item>,
                    )}
                </List>
            </ScrollArea>
        </Paper>
    );
};

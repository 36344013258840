import { updateComposeModelAction } from '@app/ui/page/detail/PagePathwayEnrolled/action';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { DefaultDocument } from '@app/constant/default/DefaultDocument';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { selectPageAsset } from '@app/client/selector/PageSelector';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { CA } from '@app/type/framework/core/CoreAction';
import { AssetModel } from '@app/type/model/AssetModel';
import { RouteLink } from '@app/ui/control/RouteLink';
import { Spacer } from '@app/ui/layout/Spacer';
import { Asset } from '@app/ui/model/Asset';
import { Text } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';
import { Action } from '@app/client/action';


export const AssetList : RFC<Props> = ({
    assetList, title = 'Documents',
}) => {

    const { dialogModalStateType, model } = useSelector(selectPageAsset);

    const dispatch = useDispatch();

    const handleChangeCompose  = (newAsset : AssetModel) : CA =>
        dispatch(updateComposeModelAction(newAsset));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Asset));

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            modelType={ModelType.Asset}
            onNewModal={handleNewModal}
            newButtonContent='Upload'
            composingModel={model}
            modelList={assetList}
            title={title}
            noItemsContent={
                <Text
                    fs='italic'
                    size='xl'
                    fw={200}
                    c='dimmed'>
                    {'- No Documents -'}
                </Text>
            }
            renderItem={item =>
                <RouteLink model={item}>
                    <Asset
                        document={DefaultDocument}
                        mode={ControlModeType.Row}
                        value={item as AssetModel}
                        showManageControls={true}
                        relationshipList={[]} />
                    <Spacer showLine={false} />
                </RouteLink>
            }
            renderCompose={
                <Asset
                    document={DefaultDocument}
                    onChange={handleChangeCompose}
                    mode={ControlModeType.Create}
                    showManageControls={false}
                    relationshipList={[]}
                    value={model} />
            } />
    );
};

import { ChatContext } from './ChatContext';
import { Box } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ChatView : RFC = () => {

    const { chat } = useContext(ChatContext);

    return (
        <Box>
            {`Chat Details ${chat?.title ?? 'No Title'}`}
        </Box>
    );
};

import { ContentConclusion  } from '@app/ui/model/Chat/Content/ContentConclusion';
import { ContentCodeBlock } from '@app/ui/model/Chat/Content/ContentCodeBlock';
import { ContentParagraph } from '@app/ui/model/Chat/Content/ContentParagraph';
import { ContentUnknown } from '@app/ui/model/Chat/Content/ContentUnknown';
import { ChatContentType } from '@app/constant/enum/chat/ChatContentType';
import { ContentHeader } from '@app/ui/model/Chat/Content/ContentHeader';
import { ContentTitle } from '@app/ui/model/Chat/Content/ContentTitle';
import { ContentImage } from '@app/ui/model/Chat/Content/ContentImage';
import { ContentQuote } from '@app/ui/model/Chat/Content/ContentQuote';
import { ContentList } from '@app/ui/model/Chat/Content/ContentList';
import { ContentLink } from '@app/ui/model/Chat/Content/ContentLink';
import { ContentReferenceView } from './ContentReferenceView';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import {
    ContentParagraph as ContentParagraphType, ContentCodeBlock  as ContentCodeBlockType,
    ContentUnknown   as ContentUnknownType,   ContentHeader     as ContentHeaderType,
    ContentTitle     as ContentTitleType,     ContentImage      as ContentImageType,
    ContentQuote     as ContentQuoteType,     ContentList       as ContentListType,
    ContentLink      as ContentLinkType,      ContentConclusion as ContentConclusionType,
} from '@app/type/content';


export const ContentSection : RFC<Props> = ({
    isReferenceView = false,
    researchControlId,
    content,
}) => {

    if(isReferenceView)
        return <ContentReferenceView content={content} />;

    switch(content.contentType) {
        case ChatContentType.Conclusion:
            return <ContentConclusion
                content={content as ContentConclusionType}
                researchControlId={researchControlId} />;

        case ChatContentType.CodeBlock:
            return <ContentCodeBlock
                content={content as ContentCodeBlockType}
                researchControlId={researchControlId} />;

        case ChatContentType.Paragraph:
            return <ContentParagraph
                content={content as ContentParagraphType}
                researchControlId={researchControlId} />;

        case ChatContentType.Unknown:
            return <ContentUnknown
                content={content as ContentUnknownType}
                researchControlId={researchControlId} />;

        case ChatContentType.Header:
            return <ContentHeader
                content={content as ContentHeaderType}
                researchControlId={researchControlId} />;

        case ChatContentType.Title:
            return <ContentTitle
                content={content as ContentTitleType}
                researchControlId={researchControlId} />;

        case ChatContentType.Image:
            return <ContentImage
                content={content as ContentImageType}
                researchControlId={researchControlId} />;

        case ChatContentType.Quote:
            return <ContentQuote
                content={content as ContentQuoteType}
                researchControlId={researchControlId} />;

        case ChatContentType.List:
            return <ContentList
                content={content as ContentListType}
                researchControlId={researchControlId} />;

        case ChatContentType.Link:
            return <ContentLink
                content={content as ContentLinkType}
                researchControlId={researchControlId} />;
    }

    return (
        <Box>
            {`Unknown (${content.hash},${content.contentType})`}
        </Box>
    );
}

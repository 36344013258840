import dynamic from 'next/dynamic';


const ReactQuill = dynamic(() => import('react-quill'), {
    ssr : false,
});


// eslint-disable-next-line import/no-default-export
export default ReactQuill;

import { Grid, Paper, Stack, Title } from '@mantine/core';
import { ContentFollowupResearchProps } from '../type';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { ChatContext } from '../ChatContext';
import { Chat } from '@app/ui/model/Chat';
import { ChatModel } from '@app/type/model/ChatModel';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ContentFollowupResearch : RFC<ContentFollowupResearchProps> = ({
    content,
}) => {

    const { relatedChatListGrouped, belongsToModel  } = useContext(ChatContext);

    // console.log('content', {
    //     relatedChatListGrouped,
    //     content,
    // });

    if(!relatedChatListGrouped)
        return null;

    const relatedChatList = relatedChatListGrouped[content.hash as string];

    if(!relatedChatList)
        return null;

    // debugger;

    return (
        <Stack>
            <Title order={3}>
                {'Followup Research'}
            </Title>
            {relatedChatList.map(chat =>
                <Grid key={chat.hash}>
                    <Grid.Col span={1} />
                    <Grid.Col span={11}>
                        <Paper shadow='sm'>
                            <Chat
                                key={chat.hash}
                                relatedChatListGrouped={relatedChatListGrouped}
                                belongsToModel={belongsToModel}
                                mode={ControlModeType.Row}
                                value={chat as ChatModel} />
                        </Paper>
                    </Grid.Col>
                </Grid>,
            )}
        </Stack>
    );
};

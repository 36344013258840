import { TabValue } from '../constant';
import { Tabs } from '@mantine/core';
import { AssetTab } from './AssetTab';
import { RFC } from '@app/ui/type';
import React from 'react'


export const HeaderTabs : RFC = () =>
    <Tabs.List>
        <AssetTab
            title={TabValue.Research}
            tabValue={TabValue.Research} />
        <AssetTab
            title={TabValue.Relationships}
            tabValue={TabValue.Relationships} />
        {/* <AssetTab
            title={TabValue.Tasks}
            tabValue={TabValue.Tasks} /> */}
        <AssetTab
            title={TabValue.Notes}
            tabValue={TabValue.Notes} />
        <AssetTab
            title={TabValue.Revisions}
            tabValue={TabValue.Revisions} />
    </Tabs.List>

import { PanelPlaceholder } from '@app/ui/layout/PanelPlaceholder';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { ObjectiveEdit } from './ObjectiveEdit';
import { ObjectiveRow } from './ObjectiveRow';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Objective : RFC<Props> = ({
    selectedChatHash,
    value : objective,
    defaultTab,
    onChange,
    mode,
}) => {

    if (!objective)
        return <LoadingSpinner label='Loading Objective...' />;

    defaultTab ||= 'overview';

    switch (mode) {

        case ControlModeType.Row :
            return <ObjectiveRow
                selectedChatHash={selectedChatHash}
                defaultTab={defaultTab}
                objective={objective} />;

        case ControlModeType.Create:
            return <ObjectiveEdit
                selectedChatHash={selectedChatHash}
                defaultTab={defaultTab}
                objective={objective}
                onChange={onChange} />;

        default: return <PanelPlaceholder label={`Objective ControlModeType ${mode}`} />;
    }
};

import AnalysisPic from '@app/asset/document-magnifying-glass.png';
import { Center, Tabs, Title, rem, Text } from '@mantine/core';
import { TabValue } from '../../constant';
import Image from 'next/image';
import { RFC } from '@app/ui/type';


export const PanelAnalysis : RFC = () => {

    const scale = 3;

    return (
        <Tabs.Panel
            value={TabValue.Analysis}
            p='xl'>
            <Title
                order={1}
                fw={200}
                size={rem(52)}>
                {'Researching...'}
            </Title>
            <Text>
                {'This will take a few moments...'}
            </Text>
            <Center>
                <Image
                    src={AnalysisPic.src}
                    width={1024 / scale}
                    height={1024 / scale}
                    alt='Researching...' />
            </Center>
        </Tabs.Panel>
    );
};

import { Box, Button, Stack, Table, Tabs, Text, rem } from '@mantine/core';
import { FcQuestions } from 'react-icons/fc';
import { toTruncatedString } from './Util';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';
import { TabProps } from './type';


export const TabHeader : RFC<TabProps> = ({
    chatListGrouped, activeTab,
}) => {
    const { isDesktop } = useContext(App);

    const isEmptyList = Object.values(chatListGrouped).length === 0;

    // create a tab for the first element in each group
    return (
        <Tabs.List>
            {Object.values(chatListGrouped).map(chats =>
                <Tabs.Tab
                    value={chats[0].groupHash!}
                    key={chats[0].groupHash}
                    component={Box}>
                    <Table
                        withRowBorders={false}
                        style={{
                            boxShadow              : activeTab === chats[0].groupHash ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : '',
                            backgroundColor        : activeTab === chats[0].groupHash ? '#fff' : '',
                            borderTopLeftRadius    : rem(20),
                            borderBottomLeftRadius : rem(20),
                        }}>
                        <Table.Tr>
                            {isDesktop &&
                                <Table.Td>
                                    <FcQuestions
                                        style={{
                                            opacity : 0.4,
                                        }}
                                        size={48} />
                                </Table.Td>
                            }
                            <Table.Td
                                p='md'>
                                <Stack

                                    gap={0}>
                                    <Text
                                        fz={rem(activeTab === chats[0].groupHash ? rem(24) : rem(14) )}
                                        fw={activeTab === chats[0].groupHash
                                            ? 700
                                            : 400
                                        }>
                                        {toTruncatedString(chats[0].title, 20)}
                                    </Text>
                                    {isDesktop && (
                                        <Text
                                            c={activeTab === chats[0].groupHash ? '' : 'dimmed'}
                                            mt={0}
                                            pt={0}
                                            fz='sm'>
                                            <Box fz='xs'>
                                                {new Date(chats[0].createdAt!).toLocaleDateString()}
                                                {' at '}
                                                {new Date(chats[0].createdAt!).toLocaleTimeString()}
                                            </Box>
                                        </Text>
                                    )}
                                </Stack>
                            </Table.Td>
                        </Table.Tr>
                    </Table>
                </Tabs.Tab>,
            )}
            <Tabs.Tab value='NEW_RESEARCH_TAB'>
                <Button
                    disabled={isEmptyList}
                    aria-label='New Research'
                    fz={rem(20)}
                    size='md'
                    variant='outline'>
                    {'+ New Research'}
                </Button>
            </Tabs.Tab>
        </Tabs.List>
    )
}

export const toTruncatedString = (input : string | null | undefined, n : number) : string => {
    // Check if input is null, undefined or empty
    if (!input) return '';

    // Return the input as is if it's shorter than n
    if (input.length <= n) return input;

    // Otherwise, truncate and append "..."
    return `${input.substring(0, n)}...`;
};

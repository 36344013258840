
import { Box, Text, rem, Badge, Group, Stack } from '@mantine/core';
import { BaseReferenceRowProps } from './type';
import classes from './ContentSection.module.css';
import { RFC } from '@app/ui/type';


export const BaseReferenceRow : RFC<BaseReferenceRowProps> = ({
    referenceHash : _referenceHash,
    contentType,
    children,
}) => {

    return (
        <Box className={classes.researchReference}>
            <Stack
                p='md'
                m='sm'>
                <Group justify='space-between'>
                    <Text
                        ml='sm'
                        fz={rem(22)}>
                        {'Reference'}
                    </Text>
                    <Badge variant='outline'>
                        {contentType}
                    </Badge>
                </Group>
                {children}
            </Stack>
        </Box>
    );
}

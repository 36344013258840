import { FormEditCreateModel } from '@app/ui/form/FormEditCreateModel';
import { AdditionalFields } from '@app/ui/layout/AdditionalFields';
import { RFC } from '@app/ui/type';
import { ViewProps } from './type';


export const AssetCreate : RFC<ViewProps> = ({
    asset, onChange,
}) => {

    const { title, description } =  asset;

    const handleChangeFieldText = (text : string, name ?: string) : void => {
        if (!name) throw new Error('Name and text are required');

        return onChange && onChange({ ...asset, [name] : text });
    };

    return (
        <FormEditCreateModel
            onChange={handleChangeFieldText}
            description={description}
            label='Upload a Document'
            title={title}
            value=''>
            <AdditionalFields />
        </FormEditCreateModel>
    );
};

import { TriageLevelType } from '@app/constant/enum/ui/TriageLevelTypeEnum';
import { LayoutModelDetail } from '@app/ui/template/LayoutModelDetail';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { FileUploader } from '@app/ui/control/FileUploader';
import { TriageBadge } from '@app/ui/control/TriageBadge';
import { ModelStash } from '@app/type/model/ModelStash';
import { NoContent } from '@app/ui/control/NoContent';
import { useDispatch } from '@app/client/hook';
import { Action } from '@app/client/action';
import { Grid } from '@mantine/core';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';


export const ContactView : RFC<ViewProps> = ({
    contact,
}) => {
    const dispatch = useDispatch();

    const handleUploadComplete = (
        model      : CoreModel,
        modelStash : ModelStash,
    ) : void => {
        dispatch(Action.updateStashNoSync(modelStash));
    };

    const handleUploadChange = (_model : CoreModel) : void => {};

    return (
        <LayoutModelDetail
            titleIcon={<TriageBadge triageLevel={TriageLevelType.Low} /> }
            title={contact.title || 'Untitled'}
            description={contact.description || '[no description]'}>
            <Grid >
                <Grid.Col
                    p='xl'
                    span={2}>
                    <FileUploader
                        onUploadComplete={handleUploadComplete}
                        onChange={handleUploadChange}
                        value={contact}>
                        <NoContent title='Upload Document - Drag and Drop or Click'/>
                    </FileUploader>
                </Grid.Col>
                <Grid.Col span={8}>
                    {'Right Side'}
                </Grid.Col>
            </Grid>
        </LayoutModelDetail>
    );
};

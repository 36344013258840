import { Box, Text, Chip, Grid, Group, Paper, Textarea, rem } from '@mantine/core';
import { selectResearchControlList } from '@app/client/selector';
import { MakeChat } from '@app/constant/default/DefaultChat';
import { useDispatch, useSelector } from '@app/client/hook';
import { GiArchiveResearch } from 'react-icons/gi';
import { ResearchTitle } from './ResearchTitle';
import { RightSection } from './RightSection';
import { Action } from '@app/client/action';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const ResearchBox : RFC<Props> = ({
    relationshipConfig, researchControlId, isResearching,
}) => {

    const dispatch = useDispatch();

    const researchControlList = useSelector(selectResearchControlList);

    const researchQueryText = researchControlList[researchControlId];

    const handleChangeChatText = (event : React.ChangeEvent<HTMLTextAreaElement>) : void => {
        dispatch(Action.updateResearchQueryText({
            researchQueryText : event.target.value,
            researchControlId,
        }));
    }

    const handleSubmitOnEnter = (event : React.KeyboardEvent<HTMLTextAreaElement>) : void => {
        if (event.key !== 'Enter')
            return;

        // debugger;

        dispatch(Action.submitResearchText({
            relationshipConfig,
            model : MakeChat({
                groupHash : researchControlId,
                query     : researchQueryText,
            }),
        }));
    }

    return (
        <Grid>
            <Grid.Col span={2} />
            <Grid.Col span={8}>
                <Paper
                    withBorder={true}
                    shadow='xl'
                    p='xl'
                    style={{
                        backgroundColor : '#f0f0f0',
                    }}>
                    <Group wrap='nowrap'>
                        <Box>
                            <GiArchiveResearch size={90} />
                        </Box>
                        <Box w='100%'>
                            <Textarea
                                placeholder={isResearching ? 'What can I help you with?' : 'Researching'}
                                rightSection={<RightSection isResearching={isResearching} />}
                                onChange={handleChangeChatText}
                                onKeyDown={handleSubmitOnEnter}
                                label={<ResearchTitle />}
                                value={researchQueryText}
                                disabled={isResearching}
                                autosize={false}
                                radius='md'
                                minRows={3}
                                size='lg'
                                ml='md'
                                mr='md' />
                        </Box>
                    </Group>
                    <Chip.Group >
                        <Group
                            mt='md'
                            justify='center'>
                            <Text
                                fw='bold'
                                fz='lg'>
                                {'Examples'}
                            </Text>
                            <Chip variant='outline'>
                                <Text
                                    fz={rem(18)} >
                                    {'Explain possible legal issues'}
                                </Text>
                            </Chip>
                            <Chip variant='outline'>
                                <Text
                                    fz={rem(18)} >
                                    {'Does this infringe on any patents?'}
                                </Text>
                            </Chip>
                            <Chip variant='outline'>
                                <Text
                                    fz={rem(18)} >
                                    {'How can I cut costs?'}
                                </Text>
                            </Chip>
                        </Group>
                    </Chip.Group>
                </Paper>
            </Grid.Col>
            <Grid.Col span={2} />
        </Grid>
    );
};

import { selectLookupRelationship } from '@app/client/selector/ListSelector';
import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultChat } from '@app/constant/default/DefaultChat';
import { CoreItemRow } from '@app/ui/layout/CoreItemRow';
import { useSelector } from '@app/client/hook';
import { Text } from '@mantine/core';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';


export const AssetRow : RFC<ViewProps> = ({
    asset, showManageControls,
}) => {

    const lookupRelationship = useSelector(selectLookupRelationship);

    const filterAssetDocumentList = (
        relationship : RelationshipModel,
    ) : boolean =>
        relationship.relationshipType === RT.UploadedTo
        && relationship.destModelType === ModelType.Asset
        && relationship.destModelId   === asset.hash;

    const assetDocumentRelationshipList =
        Object.values(lookupRelationship)
            .filter(filterAssetDocumentList);

    let managedControl = null;

    if(showManageControls)
        managedControl = (
            <ContentContextMenu
                researchControlId='figurethisout'
                isVisible={showManageControls}
                belongsToModel={asset}
                chat={DefaultChat}
                model={asset} />
        );

    return (
        <CoreItemRow
            model={asset}
            manageControl={managedControl}>
            <Text
                c='dimmed'
                size='xs'>
                {`Rev ${assetDocumentRelationshipList.length}`}
            </Text>
        </CoreItemRow>
    );
};

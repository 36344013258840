import { Orientation } from '@app/constant/framework/Orientation';
import { Notebook } from '@app/ui/control/Notebook';
import { PanelNotesProps } from '../../type';
import { TabValue } from '../../constant';
import { Tabs } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PanelNotes : RFC<PanelNotesProps> = ({ asset }) =>
    <Tabs.Panel
        p='xl'
        value={TabValue.Notes}>
        <Notebook
            orientation={Orientation.Vertical}
            model={asset} />
    </Tabs.Panel>

import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { RFC } from '@app/ui/type';
import classes from './style.module.css';
import { Grid } from '@mantine/core';
import { Props } from './type';


export const LayoutModelDetail : RFC<Props> = ({
    title, children,
}) => {

    if (!title)
        return <LoadingSpinner label='Loading Details 1' />;

    return (
        <Grid className={classes.grid}>
            <Grid.Col span={12}>
                {children}
            </Grid.Col>
        </Grid>
    );
};

import { Divider } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Spacer : RFC<Props> = ({
    showLine = false,
}) =>
    <Divider
        variant={showLine ? 'dotted' : 'none'}
        p='sm'
        m='sm' />;

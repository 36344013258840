import { TriageLevelType } from '@app/constant/enum/ui/TriageLevelTypeEnum';
import { ThemeIcon } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import {
    IconMoodNeutral, IconExclamationMark,
    IconMoodHappy, IconAsterisk,
    IconQuestionMark,
} from '@tabler/icons-react';


export const TriageBadge : RFC<Props> = ({ triageLevel }) => {

    const gradientSets = {
        [TriageLevelType.Highest] : { from : '#00867d', to : '#00867d' },
        [TriageLevelType.High   ] : { from : '#ff8c40', to : '#ff8c40' },
        [TriageLevelType.Medium ] : { from : '#884ca3', to : '#884ca3' },
        [TriageLevelType.Low    ] : { from : '#f57373', to : '#f57373' },
        [TriageLevelType.Lowest ] : { from : '#00867d', to : '#00867d' },
        [TriageLevelType.Unknown] : { from : '#424242', to : '#424242' },
    };

    switch (triageLevel) {
        case TriageLevelType.Highest: return <ThemeIcon
            color={gradientSets[triageLevel].from}
            variant='outline'
            radius='md'
            size='lg'>
            <IconAsterisk />
        </ThemeIcon>;

        case TriageLevelType.High: return <ThemeIcon
            color={gradientSets[triageLevel].from}
            variant='outline'
            radius='md'
            size='lg'>
            <IconExclamationMark />
        </ThemeIcon>;

        case TriageLevelType.Medium: return <ThemeIcon
            color={gradientSets[triageLevel].from}
            variant='outline'
            radius='md'
            size='lg'>
            <IconMoodNeutral />
        </ThemeIcon>;

        case TriageLevelType.Low: return <ThemeIcon
            color={gradientSets[triageLevel].from}
            variant='outline'
            radius='md'
            size='lg'>{'so'}
            <IconMoodHappy />
        </ThemeIcon>;

        case TriageLevelType.Lowest: return <ThemeIcon
            color={gradientSets[triageLevel].from}
            variant='outline'
            radius='md'
            size='lg'>
            <IconMoodHappy />
        </ThemeIcon>;
    }

    return <ThemeIcon
        color={gradientSets[triageLevel].from}
        variant='outline'
        radius='md'
        size='lg'>
        <IconQuestionMark />
    </ThemeIcon>;
};

import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { selectPageContact } from '@app/client/selector/PageSelector';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { updateComposeModelAction } from '@app/client/CommonAction';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { RouteLink } from '@app/ui/control/RouteLink';
import { Contact } from '@app/ui/model/Contact';
import { Spacer } from '@app/ui/layout/Spacer';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';
import { CA } from '@app/type/framework/core/CoreAction';
import { ContactModel } from '@app/type/model/ContactModel';
import { Action } from '@app/client/action';


export const ContactList : RFC<Props> = ({
    contactList,
}) => {
    const { dialogModalStateType, model } = useSelector(selectPageContact);

    const dispatch = useDispatch();

    const handleChangeCompose  = (newContact : ContactModel) : CA =>
        dispatch(updateComposeModelAction(newContact));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Contact));

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            newButtonContent='Create a Contact'
            modelType={ModelType.Contact}
            onNewModal={handleNewModal}
            modelList={contactList}
            composingModel={model}
            title='Contacts'
            renderItem={item =>
                <RouteLink model={item}>
                    <Contact
                        value={item as ContactModel}
                        mode={ControlModeType.Row}
                        showManageControls={true} />
                    <Spacer showLine={false} />
                </RouteLink>
            }
            renderCompose={
                <Contact
                    onChange={handleChangeCompose}
                    mode={ControlModeType.Create}
                    showManageControls={false}
                    value={model} />
            } />
    );
};

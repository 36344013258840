import { FormEditCreateModel } from '@app/ui/form/FormEditCreateModel';
import { RFC } from '@app/ui/type';
import { ViewProps } from './type';


export const ContactCreate : RFC<ViewProps> = ({
    contact, onChange,
}) => {
    const { title, description } =  contact;

    const handleChangeFieldText = (text : string, name ?: string) : void => {
        if (!name)
            throw new Error('Name and text are required');

        return onChange && onChange({
            ...contact,
            [name] : text,
        });
    };

    return (
        <FormEditCreateModel
            onChange={handleChangeFieldText}
            description={description}
            label='Create a Contact'
            title={title}
            value=''>
            {/* {'Additional Fields'} */}
        </FormEditCreateModel>
    );
};

import { ContentHeaderLevel } from '@app/constant/enum/chat/ContentHeaderLevel';
import { ContentListStyle } from '@app/constant/enum/chat/ContentListStyle';
import { ChatContentType } from '@app/constant/enum/chat/ChatContentType';
import { Box, List, Title, TitleOrder, rem, Text } from '@mantine/core';
import { CodeHighlight } from '@mantine/code-highlight';
import { toTruncatedString } from '../ModelChat/Util';
import { BaseReferenceRow } from './BaseReferenceRow';
import { ContentReferenceViewProps } from './type';
import rehypeHighlight from 'rehype-highlight';
import { useHover } from '@mantine/hooks';
import Markdown from 'react-markdown';
import { RFC } from '@app/ui/type';
import {
    ContentConclusion, ContentCodeBlock, ContentParagraph,
    ContentContent,    ContentUnknown,   ContentHeader,
    ContentTitle,      ContentImage,     ContentQuote,
    ContentList,       ContentLink,
} from '@app/type/content';


export const ContentReferenceView : RFC<ContentReferenceViewProps> = ({
    content,
}) => {
    const { hovered : _hovered, ref } = useHover();

    switch(content.chatContentType) {
        case ChatContentType.Conclusion:{
            const { chatContentType, text, hash } = content as ContentConclusion;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <Markdown rehypePlugins={[rehypeHighlight]}>
                        {text}
                    </Markdown>
                </BaseReferenceRow>
            );
        }
        case ChatContentType.CodeBlock:{
            const { hash, chatContentType, code, language } = content as ContentCodeBlock;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <CodeHighlight
                        language={language}
                        code={code!} />
                </BaseReferenceRow>
            );
        }
        case ChatContentType.Paragraph:{
            const { chatContentType, text, hash } = content as ContentParagraph;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <Markdown rehypePlugins={[rehypeHighlight]}>
                        {text}
                    </Markdown>
                </BaseReferenceRow>
            )
        }
        case ChatContentType.Content:{
            const _model = content as ContentContent;
            // console.log('Content', model);
        } break;

        case ChatContentType.Unknown:{
            const model = content as ContentUnknown;
            console.log('Unknown', model);
        } break;

        case ChatContentType.Header:{
            const { level, chatContentType, text, hash } = content as ContentHeader;

            const levelMap : Record<ContentHeaderLevel, TitleOrder> = {
                [ContentHeaderLevel.Unknown] : 1,
                [ContentHeaderLevel.H1]      : 1,
                [ContentHeaderLevel.H2]      : 2,
                [ContentHeaderLevel.H3]      : 3,
                [ContentHeaderLevel.H4]      : 4,
                [ContentHeaderLevel.H5]      : 5,
                [ContentHeaderLevel.H6]      : 6,
            };

            const order = levelMap[level!];

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <Title order={order}>
                        {toTruncatedString(text, 100)}
                    </Title>
                </BaseReferenceRow>
            );
        }
        case ChatContentType.Title: {
            const { chatContentType, text, hash } = content as ContentTitle;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <Title
                        variant='gradient'
                        size={rem(18)}
                        order={1}
                        c='blue'
                        fw={300}>
                        {text}
                    </Title>
                </BaseReferenceRow>
            )
        }

        case ChatContentType.Image:{
            const { alt, width, height, url, chatContentType, hash } = content as ContentImage;

            return (
                <BaseReferenceRow
                    referenceHash={hash}
                    contentType={chatContentType}>
                    <img
                        height={height! / 3}
                        width={width! / 3}
                        alt={alt!}
                        src={url!} />
                </BaseReferenceRow>
            );
        }
        case ChatContentType.Quote:{
            const { citation, text, chatContentType, hash } = content as ContentQuote;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <Text
                        fw={500}
                        size='lg'>
                        {toTruncatedString(text, 70)}
                    </Text>
                    <Text c='dimmed'>
                        {`- ${toTruncatedString(citation, 70)}`}
                    </Text>
                </BaseReferenceRow>
            );
        }
        case ChatContentType.List:{
            const{
                style, items, chatContentType, hash,
            } = content as ContentList;

            let listType : 'unordered' | 'ordered' | undefined = undefined;

            if(style === ContentListStyle.Ordered)
                listType = 'ordered';
            else if(style === ContentListStyle.Unordered)
                listType = 'unordered';

            return (
                <Box
                    ref={ref}
                    mt='xl'>
                    <BaseReferenceRow
                        contentType={chatContentType}
                        referenceHash={hash}>
                        <List
                            pb='xs'
                            pl='xl'
                            type={listType}>
                            {items!.map((item, index) =>
                                <List.Item
                                    style={{ marginBottom : rem(8) }}
                                    key={`${item}-${index}`}>
                                    {toTruncatedString(item, 100)}
                                </List.Item>,
                            )}
                        </List>
                    </BaseReferenceRow>
                </Box>

            );
        }

        case ChatContentType.Link:{
            const { text, url, chatContentType, hash } = content as ContentLink;

            return (
                <BaseReferenceRow
                    contentType={chatContentType}
                    referenceHash={hash}>
                    <a href={url}>
                        {text}
                    </a>
                </BaseReferenceRow>
            );
        }    }

    return (
        <Box>
            <Title order={3}>
                {`Unknown (${content.hash},${content.contentType})`}
            </Title>
        </Box>
    );
}

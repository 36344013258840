import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const AdditionalFields : RFC = () => {

    return (
        <Box>
            {'hello additional fields conponnet'}
        </Box>
    );
};


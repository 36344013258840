import { TitleField } from '@app/ui/control/TitleField';
import { FieldText } from '@app/ui/form/FieldText';
import { RFC } from '@app/ui/type';
import { ViewProps } from './type';


export const ObjectiveEdit : RFC<ViewProps> = ({
    objective, onChange,
}) => {

    const { description : _description, title } =  objective;

    const handleChangeFieldText = (text : string, name ?: string) : void => {
        if (!name) throw new Error('Name and text are required on Objective');

        return onChange && onChange({ ...objective, [name] : text });
    };

    return (
        <>
            <TitleField>
                {'Create Objective'}
            </TitleField>
            <FieldText
                onChange={handleChangeFieldText}
                value={title}
                label='What do you want to accomplish in this meeting?'
                description='i.e "Discuss the new product launch"'
                name='title' />
        </>

    );
};

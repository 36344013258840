export const transcriptList = [{
    text      : 'I did it, I stole the statue of liberty!',
    createdAt : '2022-02-02',
}, {
    text      : 'Dont confess in here, i dont want to hear it',
    createdAt : '2022-02-02',
}];
/* eslint-disable max-len */

// Sample data for the publications table
export const MockPublicationList = [
    {
        title   : 'Solar Power Integration in Ray Guns',
        author  : 'Dr. James Green',
        journal : 'Journal of Renewable Energy',
        year    : 2021,
        doi     : '10.1234/jre.2021.56789',
        url     : 'https://patents.example.com/solar-shrinking-mower-2021',
    },
    {
        title   : 'Innovations in Solar-Powered Shrinking Equipment',
        author  : 'Dr. Sarah Johnson',
        journal : 'Green Technology Review',
        year    : 2022,
        doi     : '10.5678/gtr.2022.12345',
        url     : 'https://patents.example.com/solar-shrinking-equipment-2022',
    },
    {
        title   : 'Efficiency Improvements in Solar Ray Guns',
        author  : 'Dr. Robert Brown',
        journal : 'Journal of Sustainable Engineering',
        year    : 2020,
        doi     : '10.9012/jse.2020.67890',
        url     : 'https://patents.example.com/solar-shrinking-mower-efficiency-2020',
    },
    {
        title   : 'Battery Technologies for Solar-Powered Ray Guns',
        author  : 'Dr. Emily White',
        journal : 'Energy Storage Journal',
        year    : 2023,
        doi     : '10.3456/esj.2023.23456',
        url     : 'https://patents.example.com/solar-shrinking-mower-battery-2023',
    },
    {
        title   : 'Environmental Impact of Solar Ray Guns',
        author  : 'Dr. Michael Blue',
        journal : 'Journal of Environmental Science',
        year    : 2021,
        doi     : '10.7890/jes.2021.34567',
        url     : 'https://patents.example.com/solar-shrinking-mower-environment-2021',
    },
    {
        title   : 'Autonomous Solar-Powered Ray Guns',
        author  : 'Dr. Olivia Black',
        journal : 'Journal of Autonomous Systems',
        year    : 2022,
        doi     : '10.1012/jas.2022.12345',
        url     : 'https://patents.example.com/solar-shrinking-mower-autonomous-2022',
    },
    {
        title   : 'Design and Materials for Solar Ray Guns',
        author  : 'Dr. Alan Smith',
        journal : 'Journal of Materials Engineering',
        year    : 2021,
        doi     : '10.1023/jme.2021.67890',
        url     : 'https://patents.example.com/solar-shrinking-mower-design-2021',
    },
    {
        title   : 'Solar Panel Efficiency in Ray Gun Applications',
        author  : 'Dr. Jane Grey',
        journal : 'Solar Energy Journal',
        year    : 2020,
        doi     : '10.1045/sej.2020.23456',
        url     : 'https://patents.example.com/solar-shrinking-mower-panel-2020',
    },
    {
        title   : 'Safety Features in Solar-Powered Ray Guns',
        author  : 'Dr. David Red',
        journal : 'Journal of Safety Engineering',
        year    : 2023,
        doi     : '10.1067/jse.2023.34567',
        url     : 'https://patents.example.com/solar-shrinking-mower-safety-2023',
    },
    {
        title   : 'Market Trends for Solar-Powered Ray Guns',
        author  : 'Dr. Susan Yellow',
        journal : 'Journal of Market Analysis',
        year    : 2022,
        doi     : '10.1089/jma.2022.45678',
        url     : 'https://patents.example.com/solar-shrinking-mower-market-2022',
    },
    {
        title   : 'Cost Analysis of Solar Ray Gun Production',
        author  : 'Dr. Nancy Silver',
        journal : 'Economics of Green Technology',
        year    : 2023,
        doi     : '10.1122/egt.2023.78901',
        url     : 'https://patents.example.com/solar-shrinking-mower-cost-2023',
    },
    {
        title   : 'User Experience with Solar-Powered Ray Guns',
        author  : 'Dr. George Gold',
        journal : 'Journal of Consumer Research',
        year    : 2021,
        doi     : '10.1109/jcr.2021.34567',
        url     : 'https://patents.example.com/solar-shrinking-mower-user-2021',
    },
    {
        title   : 'Maintenance of Solar-Powered Ray Guns',
        author  : 'Dr. Lisa Orange',
        journal : 'Journal of Maintenance Engineering',
        year    : 2020,
        doi     : '10.1134/jme.2020.56789',
        url     : 'https://patents.example.com/solar-shrinking-mower-maintenance-2020',
    },
    {
        title   : 'Future Prospects for Solar-Powered Ray Guns',
        author  : 'Dr. Mark Purple',
        journal : 'Journal of Future Technologies',
        year    : 2022,
        doi     : '10.1145/jft.2022.12345',
        url     : 'https://patents.example.com/solar-shrinking-mower-future-2022',
    },
];


export const exampleTranscriptA = `
B: Bowel disorders, it is what it is.
I: How old were you when you first started to realize you were having problems with your digestion?
B: I was, uh, 21 and a half, to be exact, yeah.
I: And do you suffer from celiac or, how would you define your discomfort?
B: My colonoscopy says no celiac and not inflammatory bowel disease. My blood marker test says I have inflammatory bowel disease. I would label myself definitely gluten intolerant.
I: And for the record, can you describe what that means? Gluten intolerance? As you would describe it.
B: Gluten intolerance means that you, your body just does not digest or break down or really absorb gluten. And that is a protein that is found in wheat. I would also say that I don’t handle processed wheat well either. Um, and the symptoms are across the board. For me personally, um, I get, I’ll get joint pains, exhaustion, um, and I just feel incredibly full. After four or five bites, if I’m having, say, pasta or something where it’s just, after four or five bites, I can’t eat anymore, feel nauseous. That’s actually where the symptoms really first started.
I: Was there anything else beyond that?  Say migraine headaches or …
B: Headaches. I have, and it’s gotten a lot better, I had, um, pretty bad hormonal acne, is what they would call it. Went across the board trying to treat it. I tried creams and antibiotics, retin-As, all that stuff. When I started to cut out gluten and wheats, my skin cleared up the best it’s ever been. I even went on Accutane and, I was on Accutane for five months and that is exactly when my symptoms would appear. I’ve been completely healthy my entire life.
I: Do you think there is a connection between …
B: Yes. I, well, studies have shown that if you possibly have Crohn’s disease or ulcerative colitis and you go on Accutane, there is research coming out that, it can set those diseases off. Um, and I just, I can’t, I think that’s what happened to me because of, like I said, I was completely healthy and then one day I’m having bowel issues and medications.
I: You said you’ve seen doctors, medical doctors, what was that process like of getting tested? What was the response?
B: Well, I went to, when I first got sick, I went to my internal medicine doctor and he’s like, “I don’t know if it’s acid reflux or what, so I’m gonna do a blood test on you.” And he did a blood panel, that’s when he said, “OK, according to these markers you have IBD, go to a gastrologist.”  And, I went to one that was recommended, um, and she was just like, “OK, I wanna scope you.”  I told her about all these symptoms and when I told her I’d been on Accutane, she kind of made a look like, “Oh, OK.” And then I have a family history, unfortunately, with inflammatory bowel disease. Both of my younger sisters have ulcerative colitis. I have an uncle who is deceased who had ulcerative colitis, and two or three second cousins that have ulcerative colitis and Crohn’s.
I: Can you describe what ulcerative colitis is, as you would describe it?
B: Um, it’s just your intestines not really absorbing the proper nutrients and inflammation, um, and that can be where, um, in any part of your gut. All the way to, Crohn’s can even burn your esophagus and mouth, all the way down through the rest of your body. It’s pretty intense. I was the healthy one. I didn’t have asthma or, not like my little sisters. The hell they went through in middle school with getting sick, I never had that.
I: Would you be willing to talk a little more about that?
B:  So, my first sister got sick right around middle school. Her symptoms were, any time she’d eat, she immediately would have to go to the bathroom, instant diarrhea. She’d break out in a sweat. She’d get [unable to transcribe], which is a kind of skin lesion, which is, we talked to the dermatologist and, but until you treat really the underlying problem, you don’t know what’s going on. Um, what else did she have? And just going from doctor to doctor. Um, they did a scope and there was a little inflammation that they did find. Um, but they still were very hesitant of saying, “Yes, you have ulcerative colitis” because she wasn’t on, your typical textbook case. I found that really hard for doctors. If it’s not black and white, they …
I: We’ve discussed in other interviews, before this, the fear of using labels in …
B: They’re terrified. I mean, I went through several doctors and then finally, um, I think kinda, and going through puberty, I and, while my sisters were going through puberty, it was really hard. With all the hormones and changing, It just kinda, I think, throws a lot of things off. And then my sister, she’s what would you call remission, but it’s never really going away. You’ll always still have it, but she been in remission for a while now. Um, found the right doctor and he didn’t label her until he started treating her.
I: So it was sort of a trial by fire?
B: Yeah, we’d kind of tell them, “Hey, can we try this? It’s not getting better.” And then my youngest sister, right around the same time, about sixth grade, she got sick as well. Different symptoms. Um, she didn’t have, she had ulcerative colitis. But, there can come blood when you go to the bathroom. And she went in, was severely sick, she went to the hospital. And filled up the little cup they have, completely, with blood. And when they went in to scope, they couldn’t find anything. But she had the blood. She had the joint ache. She has the rash. She’s trying to eat as much as she could, but her belly would distend. And they wanted to send my sister to the psych unit. They thought it was a mental thing and we’re like, “No, she’s filling up the cup.” Like, really? And it was just their reaction because they couldn’t figure out why because the gold standard in diagnosing these things is the colonoscopy. But it’s so hard with these diseases to find it.
I: Why do you think there seems to be a reluctance or a unwillingness in wanting to just get right to the point?
B: I’m not really sure. I don’t, I don’t know. I don’t know if they’re taught that in med school that, you know, that it’s not black and white. And we have found also that, when you are in a hospital setting, you don’t see the same doctor. You have a doctor for a week and then, you know, in all honesty, they weren’t all on the same page. They had their own egos and their own agendas. My sister was in there 21 days. Still, we were finally like, “This is enough. It’s not a psych problem, discharge her now.” And she was still bleeding, two weeks later, she was anemic. We ended up, by chance, being able to get into a different doctor at a different practice who would treat her as if. She was in remission for about three years, she’s out of it now, but she’s got good doctors. She’s still trying to figure out how to beat this beast.
I: Are both your sisters, and you as well, on prescription medication?
B: Um, yes. My little sister Tammy now, I think because she’s been in remission is on like a, something for acid reflux. My younger sister, she’s not on any medication. And my one sister, yes, but I don’t remember what she’s on.
I: And, none of these symptoms have been shown in your parents?
B: My mom, yeah. I remember growing up and her having bowel issues, yeah. And, it’s all on my mom’s side. My dad’s side doesn’t have any bowel issues. This gluten intolerance thing, I didn’t really understand it until I was taking a class with a friend and I was just telling her, “God, I really don’t like.” They labeled me as having GERD and put me on severe, pretty intense medication. I just don’t like the fact I have to take this medication.
I: What is GERD?
B: It’s similar to the acid reflux disease. So, a lot of it is by diet. Little things can set it off.
I: Before all this, what was your favorite food?
B: I loved pizza and burgers.
I: Would you say you ate a pretty balanced diet? Not loading up on pasta …
B: No, but it’s funny ‘cause when I was sick, that’s all I wanted, you know? That sounded really good to eat bread and crackers.
I: Is that because it was a comfort basis?
B:  No, um, I think people with that, like sugars and complex stuff, especially like all the sugar that’s really not good for you, it’s addicting, you know?  The longer you are away from it, it’s easier to stay away. Have a little and it’s like I gotta have more. It’s difficult.
I: When you started to cut out gluten and bad sugars, what was the response?
B: From like a friend?
I: Yeah. Were you a partier?
B: No. I was so, no, it was weird. I kinda went through my drinking when I was underage. By the time I turned 21, I really didn’t enjoy it. Actually, last night, I feel it. Like, I didn’t drink that much and I feel like hell the next day. Two beers. But, say if I have food and water with it, it’s still mm-mm. So, when I cheat, I’m like, “You’re gonna pay for it the next day.” But it’s hard. Not wanting to go out with friends and, you know, it gets kinda, after a while, “Oh, I’ll just have salad cause that’s really all I can eat.” I can still eat stuff, but finding restaurants that are accommodating to eating to that.
I: Is there a feeling of being ostracized? Your friends or actually from the restaurant itself?
B: Yeah, it’s complicated. I just tell them now.
I: Do you have a specific experience that jumps out in your mind?
B: Yeah, I’d just gotten, a couple of times when you ask, “Do you have a gluten-free menu?” They’re getting much better at it, but I remember when I first started, like a year and a half ago, it was still kind of like [rolls eyes], “Oh, you’re one of those people.” Um, cause some people will cut out gluten because they think it will be great for them. But it really doesn’t hurt them. So, I don’t like to be grouped into that. It’d be cheaper. Probably be easier just to be able to go with the flow.
I: How does that make you feel when you talk to someone who says they’re cutting out gluten for my diet, but they can eat bread if they desperately wanted to?
B: Um, close friends, no. Not at all. I mean, because, well, they’ve seen me not do well. I’m just not very nice either because I don’t feel well. Um, but strangers, yeah, that don’t get it. You know, there’s kind of a judgment and they, “Why are you, are you doing it to lose weight or … ?” No, I’m doing it ’cause I feel miserable. But that’s also something that’s going around like in the media, I know, it’s being hyped up as, “Cut out gluten, it’ll make you lose weight.” I think that anything, if you’re just trying, doing it to lose weight, that won’t stick.
I: How do feel with all the mass market of gluten-free this, gluten-free that?
B: It’s a little overwhelming, to be honest. You really have to look at the food labels. Are they taking out this? I still, and trying to find stuff, um, at the grocery store that I can eat, but it takes forever. I try to, at least, read every label. Grocery experiences are long [laughs].
I: Is there a specific thing that you want, but know you can’t have?
B: Yeah, that’s my downfall. I mean, I’ve found some places that are gluten-free. But, you know, I haven’t found, has anyone found a gluten-free pizza that you can make at home, send it my way ’cause [laughs] …
I: In addition to reading labels and breakdown what I can and can’t eat in the moment, how has your lifestyle changed to feel comfort?
B: With gluten intolerance, I’ve done some research. But I’ve gotten to the point where I’ve, I’m taking a class with a friend and she’s like, “I have stomach issues. Why don’t you read this book? It’s called Eat Right for Your Blood Type.” I didn’t know my blood type at the time, so I started reading the type-O diet and I was like, “Oh my god.” It describes how, based on if you eat these foods, it’s what you have. It’s so me. I had to kinda look away. Is this really me? And it was. So for me, I follow the hunter-gatherer type, meat, fruits, and vegetables. Um, and so that alone, that book and I’m so grateful for my friend, because that brought a sense of comfort.
I: It sounds like you’ve got a lot of support around you.
B: Yeah.
I: And, have you been able to pass that along to your sisters? Did they ever talk to you about what it’s like to be in middle school and have, really feel, have these differences?
B:  Oh yeah, it was really, really tough. Kids, not so much kids, but I remember the teachers would not, being, not really getting it. And my mom would try so hard to, I mean, “Hey, this is what my daughters have. There are the type of medications, heavy medication at one point that they were on. Heavy steroids that make you, not really there. Um, she’s maybe not paying attention because her pain or she’s trying not to shit her pants.” And, um, yeah they had a really hard time finding kids that understood what they were going through. And, it’s a disease on the inside that doesn’t always manifest on the outside. And I think a lot of diseases are like that. Just being aware.
I: Especially when a lot of the symptoms are connected to other diseases? Are you concerned, have you thought about at all what this could lead to?
B: That’s why I changed my diet because I know the blood markers, so, in some ways that kind of means I’m a ticking time bomb. You know, I could get the flu really, really bad. And that could set off this disease. Um, but I’m doing everything that I can in my power as far as diet that I can to control it. Because that also can set it off. I’ve adapted this new lifestyle. Exercise like three, four times a week. Um, that’s been the hardest one, to really stay committed. I’ve conquered the food part, but not putting in the exercise as well. And doing yoga ’cause I stress easily. And, that’ll feed into it, that stress will lead me into making poor food choices. So, finding the balance is what I’m really focusing on.
I: Do you feel that, when you’ve had a bad day, it’s the emotional connection, or that emotion is tied to reactions? “I feel bad, I need comfort.”
B:  That’s something to be aware of, definitely. That’s happened to me.
I: Do you think there’s a danger in people becoming so emotion-oriented?
B: Oh yeah. Um, yeah you gotta find balance you know? And being aware, it’s all kind of connected.
I: Is there a point where you’ve had to battle and fears or overwhelming emotions? And you had to bring yourself back? Trigger or phrases that help keep you in balance?
B: Um, I have to stay in the present. And not, “God, what if this happens?” ’Cause that, you know, will set it off. And that’s where I need to be, in that present and just focus on that. And when I don’t, that’s when it gets me and stresses me out. And in listening to your body is the best thing. You are your best advocate for yourself. Not taking the medical. Being in tune. It’s clear if I’ve had a bad week. I eat foods I shouldn’t have been eating. I feel really lethargic and exhausted. If you don’t want to feel like this, don’t eat it. It’s pretty immediate.
I: Do you feel you’re leaning towards organic?
B: I have found, for me, the hunter-gatherer, animal protein. I’d watch documentaries on how animals are being treated, on what they’re given and what we’re absorbing and the cancer rate and all. So, I tried vegetarian, but that means I upped my soy. A lot of soy, had a lot of grains too, even lentils, which are supposed to be really good. And I felt miserable. And I gained a lot of weight. And, they, “You know, don’t eat meat and you’ll lose weight.” For me, I felt horrible. Like, my skin was breaking out, I gained weight. I think like everyone is honestly a little bit different. It’s unfair to the consumer. I gotta eat animal protein or I’m not a very nice woman. I’m cranky. Watch out! But, on the other side, I’ve got to pay more for grass-fed animal meat that’s not injected with hormones. I think that’s what’s causing the cancer and all these problems. It’s because of what they’re giving to the animals. And the hormones. Of course we’re gonna get sick. Of course. It’s infuriating, it really is. But you pay more, a little bit, for the great meat, you know, versus paying several years down the line severe medical bills. I’m not saying you couldn’t, one day, have cancer. But you’ve done everything in your power to not get it and I just got the short end of the stick, but at least I can be in control of, at least I’m eating this. Holds me accountable.
I: Last question, what’s it been like?
B: I have a really tough stomach. Yeah we, my sisters and I, do talk about it. Definitely check in and, our poor dad. We’re all so females that, sometimes, there’s a point we’re at dinner conversations and we talk about our bowel habits and what was going on. My poor dad. So, yeah, there’s definitely an open communication and you gotta have a sense of humor. You got these issues, you have to have a sense of humor.
I: What would say to someone that provides comfort? It’s OK, this is life?
B: Um, we just, we just joke. “Hey, remember that one time when,” you know? You just have, you just kind of have a sense of humor about it, accepting what it is and try to stay positive is how we try to move forward. And be on top of it. And really choosing to have a say in relation to our digestion and stuff. Being aware. It’s different for everyone. Be in tune with what your symptoms are. You’re your best advocate.
`;

export const ChatQuestionList = [
    'If you were terminated, have you become re-employed? If so, how much more or less are you earning today in relationship to what you earned at the point of termination? Please include a detailed and itemized comparison of salary, commissions, bonuses, and all benefits between your previous position (that from which you were terminated, etc.) and your new position.',
    'Have you ever filed any type of discrimination, sexual harassment, retaliation, or wrongful termination claim or complaint before? (If so, please provide a detailed description of each claim and how it was resolved).',
    'Explain what action was taken against you that you believe was discriminatory or unlawful: (Tell us who, what, when, where, and why. Feel free to attach additional sheets of paper.)',
    'Did you report the action to anyone and, if so, to whom and when. Please also describe what investigation, if any, and what action, if any, was taken as a result of your complaint.',
    'Is there anything else you believe may be relevant, positive or negative, that you believe we should know in considering or evaluating your claim, or in representing you?',
    'If there is a grievance/complaint procedure or policy, did you follow it? Please describe what you did. If not, please explain fully why you did not follow it.',
    'Does your employer have a grievance/complaint policy/procedure that may apply? If so, please describe and if possible, attach the policy.',
    'Have others been treated differently than you for the same conduct? (If yes, please tell us who, what, when, where, and why.)',
    'What reason(s), if any, was given for the action taken against you? (Tell us who, what, when, where, and why.)',
    'Do you have any witnesses? (We will not contact anyone until we have spoken to you and obtained your consent.)',
    'Why do you believe what happened is discrimination, retaliation, or otherwise illegal? (As you marked in 8.)',
    'How, if at all, did your work environment or terms of employment change following your complaint?',
    'Has the same thing happened to others? (If yes, please tell us who, what, when, where, and why.)',
    'What is the employer\'s normal policy/practice in a situation such as yours, if applicable?',
    'How did these events impact you, and do they continue to affect you today?',
];

export const clientRedFlags = [
    'Clients who have unreasonable expectations or who seek relief no court can grant. Example: “I know this is a multi-million dollar case. I saw someone on Jenny Jones who had a claim like mine, and they recovered $1 million. My case is even better.”',
    'Social contacts such as friends, relatives, drinking buddies, etc. The emotional entanglements will only make your job harder. Besides, these clients might simply be looking for free legal advice.',
    'Clients who know enough about the law to make your life miserable. They may refuse to follow your advice because they think they know more about the law than you do.',
    'Overzealous clients driven by a need for vengeance or vindication. Example: “I don’t care how much it costs as long as you make that jerk\'s life a living Hell!”',
    'Clients looking for a shoulder to cry on or who need psychological counseling more than legal counseling. Remember you are not a shrink.',
    'Clients who make unreasonable demands of you or who ask you to engage in unethical or illegal behavior.',
    'Clients who have had multiple lawyers or who have been rejected by every other lawyer on the block.',
    'Clients who are abusive, rude, mean, overly argumentative, or who threaten you or your staff.',
    'Clients with rotten attitudes about lawyers, courts, and the legal system in general.',
    'Clients who quibble about your fee or who do not pay the retainer when requested.',
    'Clients looking for a free lawyer or the cheapest lawyer available.',
    'Clients who are high rollers and want to cut you in on the action.',
    'Clients who want to tell you how to run the case.',
    'Two or more clients seeking joint representation.',
    'Clients who have done research on their own.',
    'Cases with extreme time pressure.',
    'Clients suing on principle.',
    'Clients who habitually lie.',
    'Perpetual victims.',
];

/* eslint-enable max-len */

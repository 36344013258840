import { selectPathwayDetailChatObjectiveList, selectPathwayDetailChatTranscriptList } from '@app/client/selector/PathwaySelector';
import { PageBrowseObjective } from '@app/ui/page/browse/PageBrowseObjective';
import { DefaultObjective } from '@app/constant/default/DefaultObjective';
import { Button, Grid, Group, Title, Text, Alert } from '@mantine/core';
import { CA, CoreAction } from '@app/type/framework/core/CoreAction';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { useDispatch, useSelector } from '@app/client/hook';
import { TitleField } from '@app/ui/control/TitleField';
import { ChatContext } from './ChatContext';
import { ChatControl } from './ChatControl';
import { Action } from '@app/client/action';
import React, { useContext } from 'react';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';


export const ChatCreate : RFC<ViewProps> = ({
    onChange : _onChange,
}) => {
    const dispatch = useDispatch();

    const { chat } =  useContext(ChatContext);

    const transcriptList = useSelector(selectPathwayDetailChatTranscriptList);
    const objectiveList  = useSelector(selectPathwayDetailChatObjectiveList );

    const handleChangeObjective = (_model : CoreModel) : CA => dispatch(Action.noOp());

    const handleChangeRichText = (updatedText : string) : CA =>
        dispatch(Action.updateComposeModelDetail({
            ...chat,
            chatNotes : updatedText,
        } as CoreModel));

    // fix this af matter refactoring
    const composeObjective = DefaultObjective; //useSelector(Selector.pathwayDetailComposeObjective);

    if (!objectiveList || !transcriptList)
        return <LoadingSpinner label='Loading chat 2' />;

    const handleAddObjectiveToChat = () : CoreAction => dispatch(Action.addObjectiveToChat());

    return (
        <Grid>
            <Grid.Col span={12}>
                <TitleField>
                    {chat?.title}
                </TitleField>
                <br />
                {chat?.analysisSummary &&
                    <Alert
                        p='xl'
                        m='xl'>
                        <Group align='apart'>
                            <Title mb='md'>
                                {'Analysis Summary'}
                            </Title>
                            <Button
                                aria-label='Print'
                                variant='outline'>
                                {'Print'}
                            </Button>
                        </Group>
                        <Text>
                            {chat.analysisSummary.replaceAll('\n', '<br />')}
                        </Text>
                    </Alert>
                }
                <PageBrowseObjective
                    onAddItem={handleAddObjectiveToChat}
                    onChange={handleChangeObjective}
                    composeModel={composeObjective}
                    value={objectiveList} />
            </Grid.Col>
            <Grid.Col span={12}>
                <ChatControl
                    onChangeRichText={handleChangeRichText}
                    transcriptList={transcriptList}  />
            </Grid.Col>
        </Grid>
    );
};

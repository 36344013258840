import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultChat } from '@app/constant/default/DefaultChat';
import { CA } from '@app/type/framework/core/CoreAction';
import { ChatModel } from '@app/type/model/ChatModel';
import { useDispatch } from '@app/client/hook';
import { Chat } from '@app/ui/model/Chat';
import { Text } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';
import { Action } from '@app/client/action';


export const ChatList : RFC<Props> = ({
    relatedChatListGrouped,
    relationshipConfig = {},
    belongsToModel,
    chatList,
}) => {

    const dispatch = useDispatch();

    const handleNewModal = () : CA => dispatch(Action.noOp());

    const sortedChatList = chatList.sort((a, b) =>
        b.chatOrder - a.chatOrder,
    );

    return (
        <BrowseModelLayout
            dialogModalStateType={DialogModalStateType.Closed}
            relationshipConfig={relationshipConfig}
            onNewModal={handleNewModal}
            modelType={ModelType.Chat}
            renderCompose=''
            noItemsContent={
                <Text
                    fs='italic'
                    c='dimmed'
                    size='xl'
                    fw={200}>
                    {'- No Research -'}
                </Text>
            }
            composingModel={DefaultChat}
            modelList={sortedChatList}
            renderItem={item =>
                <Chat
                    relatedChatListGrouped={relatedChatListGrouped}
                    belongsToModel={belongsToModel}
                    mode={ControlModeType.Row}
                    value={item as ChatModel} />
            } />
    );
};

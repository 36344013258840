import { IconArrowUp } from '@tabler/icons-react';
import { Grid } from 'react-loader-spinner';
import { RightSectionProps } from './type';
import { Box, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const RightSection : RFC<RightSectionProps> = ({ isResearching }) => {

    if(isResearching)
        return (
            <Box mr={rem(50)}>
                <Grid
                    wrapperClass='grid-wrapper'
                    ariaLabel='grid-loading'
                    wrapperStyle={{}}
                    color='#4fa94d'
                    visible={true}
                    radius='12.5'
                    height='56'
                    width='56' />
            </Box>
        )

    return (
        <IconArrowUp
            size='xl'
            style={{
                cursor : 'pointer',
            }} />
    )
};

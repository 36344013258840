import { useDispatch } from '@app/client/hook';
import { Button, Group } from '@mantine/core';
import { HeaderBarProps } from '../type';
import { trpc } from '@app/utils/trpc';
import { RFC } from '@app/ui/type';
import { CA } from '@app/type/framework/core/CoreAction';
import { P } from '@app/type/framework/data/P';
import { Action } from '@app/client/action';


export const HeaderBar : RFC<HeaderBarProps> = ({ asset }) => {
    const dispatch = useDispatch();

    const { refetch } = trpc.asset.download.useQuery(asset.hash, {
        refetchOnWindowFocus : false,
        enabled              : false,
    });

    const handleDownloadAssetFile = async () : P => {
        const { data : fileDownloadUrl } = await refetch();

        window.open(fileDownloadUrl, '_blank')
    }

    const handleExtractEntities = () : CA => dispatch(Action.extractModelEntities(asset));
    const handleAnalyzeModel    = () : CA => dispatch(Action.analyzeModel(        asset));

    return (
        <Group justify='right'>
            <Button
                aria-label='Download'
                variant='outline'
                onClick={handleDownloadAssetFile}>
                {'Download'}
            </Button>
            <Button
                aria-label='Extract Entities'
                variant='subtle'
                onClick={handleExtractEntities}>
                {'XX Test'}
            </Button>
            <Button
                aria-label='Analyze Model'
                variant='subtle'
                onClick={handleAnalyzeModel}>
                {'.'}
            </Button>
        </Group>
    );
}

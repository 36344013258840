import { Box, Container, Grid, Group, Table, Tabs, Text, rem } from '@mantine/core';
import { ResearchSubSection } from '@app/constant/enum/ui/ResearchSubSectionEnum';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { CoreItemRow } from '@app/ui/layout/CoreItemRow';
import { IconGitCompare } from '@tabler/icons-react';
import React, { useContext, useState } from 'react';
import { PatentOfficeTab } from './PatentOfficeTab';
import { MockPublicationList } from './constant';
import { ShareBar } from '@app/ui/control/ShareBar';
import { PatentOrgTabs } from './PatentOrgTabs';
import { ChatContext } from './ChatContext';
import { ChatContent } from './Content';
import { useId } from '@mantine/hooks';
import { ChatModel } from '@app/type/model/ChatModel';
import { RFC } from '@app/ui/type';
import Link from 'next/link';


export const ChatRow : RFC = () => {
    const { chat } = useContext(ChatContext);

    const [value, setValue] =   useState<ResearchSubSection>(ResearchSubSection.Overview);

    const handleChangeTab = (value : ResearchSubSection) : void=> {
        setValue(value);
    }

    console.log('value', value);

    const id = useId();

    /* eslint-disable max-len */

    return (
        <CoreItemRow
            hoverEffect={false}
            rightSection={
                <ShareBar
                    model={chat as ChatModel}
                    size={IconSize.Medium} />
            }
            model={chat!}>
            <Tabs defaultValue='USPO'>
                <PatentOrgTabs />
                <Tabs.Panel value='USPO'>
                    <Text
                        fw={600}
                        ta='center'
                        mt='xl'
                        pt='md'
                        pb='lg'
                        size={rem(26)}>
                        {'United States Patent Office (USPO)'}
                    </Text>
                    <Tabs
                        orientation='horizontal'
                        onChange={handleChangeTab as (value : string | null) => void}
                        value={value}>
                        <Tabs.List grow={true}>
                            <Tabs.Tab value={ResearchSubSection.Overview}>
                                <Text
                                    fw={value === ResearchSubSection.Overview ? 600 : 200}
                                    fz={rem(
                                        value === ResearchSubSection.Overview
                                            ? 24
                                            : 18,
                                    )}>
                                    {ResearchSubSection.Overview}
                                </Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={ResearchSubSection.SimilarPatents}>
                                <Text
                                    fw={value === ResearchSubSection.SimilarPatents ? 600 : 200}
                                    fz={rem(
                                        value === ResearchSubSection.SimilarPatents
                                            ? 24
                                            : 18,
                                    )}>
                                    {ResearchSubSection.SimilarPatents}
                                </Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={ResearchSubSection.RelatedPublications}>
                                <Text
                                    fw={value === ResearchSubSection.RelatedPublications ? 600 : 200}
                                    fz={rem(
                                        value === ResearchSubSection.RelatedPublications
                                            ? 24
                                            : 18,
                                    )}>
                                    {ResearchSubSection.RelatedPublications}
                                </Text>
                            </Tabs.Tab>
                            {/* <Tabs.Tab value={ResearchSubSection.PotentialIssues}>
                                <Text
                                    fw={value === ResearchSubSection.PotentialIssues ? 600 : 200}
                                    fz={rem(
                                        value === ResearchSubSection.PotentialIssues
                                            ? 24
                                            : 18,
                                    )}>
                                    {ResearchSubSection.PotentialIssues}
                                </Text>
                            </Tabs.Tab> */}
                        </Tabs.List>
                        <Tabs.Panel value={ResearchSubSection.Overview}>
                            <Container
                                fluid={true}
                                p='xl'>
                                <ChatContent researchControlId='123' />
                            </Container>
                        </Tabs.Panel>
                        <Tabs.Panel
                            style={{
                                minHeight : rem(500),
                            }}
                            value={ResearchSubSection.SimilarPatents}>
                            <Grid>
                                {chat?.patent_publications.map(publication => (
                                    <Grid.Col
                                        key={`${id}-${publication.hash}`}
                                        span={12} >
                                        <Box
                                            mt='lg'
                                            p='md'
                                            style={{
                                                border    : '1px solid #e9e9e9',
                                                boxShadow : '10px 10px 32px -18px rgba(0,0,0,0.25)',
                                            }}>
                                            <Group
                                                wrap='nowrap'
                                                align='start'>
                                                <Group
                                                    align='start'
                                                    pt='md'>
                                                    <IconGitCompare size={50} />
                                                </Group>
                                                <Box>
                                                    <Text
                                                        fw={500}
                                                        fz={rem(32)}>
                                                        {publication.title}
                                                    </Text>
                                                    <Link href={publication.url || '[No URL]'} >
                                                        <Text
                                                            pl='xl'
                                                            mb='md'
                                                            fz={rem(26)}>
                                                            {publication.url}
                                                        </Text>
                                                    </Link>
                                                    <Box pl='xl'>
                                                        <Text
                                                            fw={600}
                                                            fz={rem(18)}>
                                                            {'Abstract'}
                                                        </Text>
                                                        <Text>
                                                            {publication.abstract}
                                                        </Text>
                                                    </Box>
                                                    <Table
                                                        ml='xl'
                                                        mt='md'>
                                                        <Table.Thead>
                                                            <Table.Tr>
                                                                <Table.Th>
                                                                    <Text
                                                                        w={rem(100)}
                                                                        fw={600}
                                                                        fz={rem(18)}>
                                                                        {'Pub #'}
                                                                    </Text>
                                                                </Table.Th>
                                                                <Table.Th>
                                                                    <Text
                                                                        fw={600}
                                                                        fz={rem(18)}>
                                                                        {'Title'}
                                                                    </Text>
                                                                </Table.Th>
                                                                <Table.Th>
                                                                    <Text
                                                                        fw={600}
                                                                        w={rem(100)}
                                                                        fz={rem(18)}>
                                                                        {'Country'}
                                                                    </Text>

                                                                </Table.Th>
                                                                <Table.Th>
                                                                    <Text
                                                                        fw={600}
                                                                        fz={rem(18)}>
                                                                        {'Details'}
                                                                    </Text>
                                                                </Table.Th>
                                                            </Table.Tr>
                                                        </Table.Thead>
                                                        <Table.Tbody>
                                                            <Table.Tr>
                                                                <Table.Td>
                                                                    <Text fz={rem(18)}>
                                                                        {publication.publication_number}
                                                                    </Text>
                                                                </Table.Td>
                                                                <Table.Td>
                                                                    <Text fz={rem(18)}>
                                                                        {publication.title}
                                                                    </Text>
                                                                </Table.Td>
                                                                <Table.Td>
                                                                    <Text fz={rem(18)}>
                                                                        {publication.country}
                                                                    </Text>
                                                                </Table.Td>
                                                                <Table.Td>
                                                                    <Text fz={rem(18)}>
                                                                        {publication.publication_description}
                                                                    </Text>
                                                                </Table.Td>

                                                            </Table.Tr>
                                                        </Table.Tbody>
                                                    </Table>

                                                </Box>

                                            </Group>
                                        </Box>
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </Tabs.Panel>
                        <Tabs.Panel
                            value={ResearchSubSection.RelatedPublications}
                            style={{
                                minHeight : rem(500),
                            }}>
                            <Table
                                fz={rem(20)}
                                highlightOnHover={true}
                                striped={true}>
                                <Table.Thead>
                                    <Table.Tr>
                                        <th>{'Title'}</th>
                                        <th>{'Year'}</th>
                                        <th>{'Url'}</th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {MockPublicationList.map((publication, index) => (
                                        <Table.Tr key={`pub-list-${index}-${id}`}>
                                            <Table.Td>
                                                <Text fz={rem(26)}>
                                                    {publication.title}
                                                </Text>
                                                <Text
                                                    ml='lg'
                                                    fw={600}>
                                                    {publication.author}
                                                </Text>
                                                <Text
                                                    ml='lg'
                                                    c='dimmed'
                                                    fw={600}>
                                                    {publication.journal}
                                                </Text>
                                            </Table.Td>
                                            <Table.Td>
                                                {publication.year}
                                            </Table.Td>
                                            <Table.Td>
                                                <Link href='https://patents.google.com/patent/EP4208005A1/en?q=(solar+powered+lawn+mower)&oq=solar+powered+lawn+mower'>
                                                    {'Link'}
                                                </Link>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                            {ResearchSubSection.RelatedPublications}
                        </Tabs.Panel>
                        <Tabs.Panel
                            value={ResearchSubSection.PotentialIssues}
                            style={{
                                minHeight : rem(500),
                            }}>
                            {ResearchSubSection.PotentialIssues}
                        </Tabs.Panel>
                    </Tabs>
                </Tabs.Panel>
                <PatentOfficeTab
                    label='Japan Patent Office (JPO)'
                    value='JPO' />
                <PatentOfficeTab
                    label='Korean Intellectual Property Office (KIPO)'
                    value='KIPO' />
                <PatentOfficeTab
                    label='World Intellectual Property Organization (WIPO)'
                    value='WIPO' />
                <PatentOfficeTab
                    label='Intellectual Property India'
                    value='IPO' />
                <PatentOfficeTab
                    label='State Intellectual Property Office (SIPO) of the Peoples Republic of China'
                    value='SPIO' />
            </Tabs>
        </CoreItemRow>
    );
}

/* eslint-enable max-len */

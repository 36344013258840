import { Button, Center, Grid, Group, Title } from '@mantine/core';
import { AudioRecorder } from '@app/ui/control/AudioRecorder';
import { Transcript } from '@app/ui/layout/Transcript';
import QuillNoSSRWrapper from './QuillWrapper';
import { useDispatch } from '@app/client/hook';
import { Spacer } from '@app/ui/layout/Spacer';
import { ChatContext } from './ChatContext';
import { ChatControlProps } from './type';
import classes from './style.module.css';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { IconSize } from '@app/constant/enum/ui/IconSizeEnum';
import { CA } from '@app/type/framework/core/CoreAction';
import { Action } from '@app/client/action';


export const ChatControl : RFC<ChatControlProps> = ({
    onChangeRichText, transcriptList,
}) => {

    const dispatch = useDispatch();

    const { chat } = useContext(ChatContext);

    const handleChatAnalysis = () : CA =>
        dispatch(Action.chatAnalysis(chat!));

    return (
        <Grid className={classes.sideGrid}>
            <Grid.Col span={12}>
                <Center>
                    <AudioRecorder size={IconSize.Small} />
                </Center>
                <Spacer showLine={false} />

                <Spacer showLine={false} />
                <Center>
                    <Button
                        aria-label='Run Analysis'
                        className={classes.debugTextLink}
                        onClick={handleChatAnalysis}
                        variant='outline'
                        size='xl'>
                        {'Run Analysis'}
                    </Button>
                </Center>
                <Group>
                    <Title
                        order={3}
                        pt='lg'>
                        {'Notes'}
                    </Title>
                    <QuillNoSSRWrapper
                        className={classes.textEditor}
                        onChange={onChangeRichText}
                        value={chat?.notes} />
                </Group>
            </Grid.Col>
            <Grid.Col span={12}>
                <Title
                    order={3}
                    pt='lg'>
                    {'Transcript'}
                </Title>
                <Group>
                    <Transcript value={transcriptList} />
                </Group>
            </Grid.Col>
        </Grid>
    );
};


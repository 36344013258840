import { AssetList } from '@app/ui/list/AssetList';
import { TabValue } from '../../constant';
import { Tabs } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PanelRevisions : RFC = () =>
    <Tabs.Panel
        p='xl'
        value={TabValue.Revisions}>
        <AssetList assetList={[]} />
    </Tabs.Panel>

import { Alert, Text, Paper, Title } from '@mantine/core';
import { AssetErrorProps } from './type';
import { RFC } from '@app/ui/type';


export const AssetError : RFC<AssetErrorProps> = ({ document }) =>
    <Paper
        shadow='xl'
        radius='lg'
        m='xl'>
        <Alert
            variant='light'
            color='orange'
            p='xl'
            title='Invalid File Format'>
            <Title order={3}>
                {'There was a problem analyzing your document, please upload PNG, JPEG, PDF, or TIFF file.'}
            </Title>
            <Text
                size='xl'
                mt='lg'>
                {document.fileName}
            </Text>
        </Alert>
    </Paper>



import { selectLookupRelationship, selectLookupContent } from '@app/client/selector/ListSelector';
import { ContentSection } from '../../../control/ContentSection/index';
import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { ContentFollowupResearch } from './ContentFollowupResearch';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { useSelector } from '@app/client/hook';
import { ChatContext } from '../ChatContext';
import { ChatContentProps } from '../type';
import { Box } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ChatContent : RFC<ChatContentProps> = ({
    researchControlId : _researchControlId,
}) => {

    const lookupRelationship = useSelector(selectLookupRelationship);
    const lookupContent      = useSelector(selectLookupContent);

    const { chat, belongsToModel } = useContext(ChatContext);

    const filterChatRelationshipList = (
        relationship : RelationshipModel,
    ) : boolean =>
        relationship.relationshipType === RT.BelongsTo
        && relationship.destModelType === ModelType.Chat
        && relationship.destModelId   === chat?.hash;

    const chatContentRelationshipList = Object.values(lookupRelationship)
        .filter(filterChatRelationshipList);

    const contentList = chatContentRelationshipList.map(relationship =>
        lookupContent[relationship.sourceModelHash as string],
    ).sort((a, b) => a.order - b.order);

    // debugger;

    return (
        <Box>
            {contentList.map(content =>
                <Box
                    style={{ position : 'relative'}}
                    key={content.hash}
                    mb='md'>
                    <ContentSection
                        researchControlId={chat!.groupHash!}
                        belongsToModel={belongsToModel!}
                        content={content}
                        chat={chat!} />
                    <ContentFollowupResearch content={content} />
                </Box>,
            )}
        </Box>
    );
};

import { Tabs, Text } from '@mantine/core';
import { AssetTabProps } from '../type';
import { RFC } from '@app/ui/type';


export const AssetTab : RFC<AssetTabProps> = ({
    tabValue, title,
}) =>
    <Tabs.Tab value={tabValue}>
        <Text size='xl'>
            {title}
        </Text>
    </Tabs.Tab>
